<template>
    <div>
        <section class="breadcrumb-area">
            <!-- <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-area-content">
                            <h1>.</h1>
                        </div>
                    </div>
                </div>
            </div> -->
        </section><!-- breadcrumb area end -->
        <!-- transformers area start -->
        <section class="transformers-area">
            <div class="container">
                <div class="transformers-box">
                    <div class="row flexbox-center">
                        <div class="col-lg-5 text-lg-left text-center">
                            <div class="transformers-content" v-if="movie.photo != null">
                                <img :src="$store.state.fileUrl + `/moviesPhoto/` + movie.photo" alt="movie" />
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="transformers-content">
                                <h2>{{ movie.name }}</h2>
                                <p>{{ movie.genres }}</p>
                                <ul>
                                    <li>
                                        <div class="transformers-left">Rejissor:</div>
                                        <div class="transformers-right">{{ movie.director }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Janr:</div>
                                        <div class="transformers-right">{{ movie.genres }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Premyera sanasi:</div>
                                        <div class="transformers-right">{{ parseDate(movie.release) }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Tili:</div>
                                        <div class="transformers-right">{{ movie.languages }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Davlat:</div>
                                        <div class="transformers-right">{{ movie.country }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Davomiyligi:</div>
                                        <div class="transformers-right">{{ durationTime(movie.duration) }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Sifati: </div>
                                        <div class="transformers-right">{{ movie.quality }} HD</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Yosh chegarasi:</div>
                                        <div class="transformers-right">{{ movie.ageLimit }} +</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Rollarda:</div>
                                        <div class="transformers-right flex-1-1-0">
                                            <div class="slide-cast">
                                                <div class="single-slide-cast mt-0" v-for="(actor, key) in movie.actors" :key="key">
                                                    <router-link :to="`/movie/actor/` + actor.id">
                                                        <img :src="$store.state.fileUrl + `/actors/` + actor.photo" alt="actor" />
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li>
                                        <div class="transformers-left">Cinema:</div>
                                        <div class="transformers-right">HB Movies  |  HW Town <a href="#" class="theme-btn">Movies House</a>Grand Seats  |  HB Max
                                            <div class="transformers-bottom">
                                                <p>May 19, 2018 <span>15h47</span></p>
                                                <p>May 19, 2018 <span>15h47</span></p>
                                                <p>May 19, 2018 <span>15h47</span></p>
                                            </div>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section><!-- transformers area end -->
        <!-- details area start -->
        <section class="details-area" v-if="movie != null">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="details-content" v-if="movie.priceType == false">
                            <div class="details-comment">
                                <div class="container-video">
                                    <video controls class="video" ref="videoPlayer">
                                        <source :src="link" type="video/mp4">
                                        Sizning brauzeringiz video tagini qo'llab-quvvatlamaydi.
                                    </video>
                                </div>
                            </div>
                            <div class="details-episodes">
                                <span v-for="(video, index) in movie.videos" :key="index" >
                                    <button @click="changeEpisode(video)" class="btn btn-secondary mr-2"> {{ (index + 1) }} - qism</button>
                                </span>
                            </div>
                            <div class="details-overview">
                                <h2>Film haqida</h2>
                                <p>{{ movie.info }}</p>
                            </div>
                            <!-- <div class="details-reply">
                                <h2>Fikr va mulohazalar</h2>
                                <form action="#">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="select-container">
                                                <input type="text" placeholder="Ismi familiya"/>
                                                <i class="icofont icofont-ui-user"></i>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="select-container">
                                                <input type="text" placeholder="Email"/>
                                                <i class="icofont icofont-envelope"></i>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="textarea-container">
                                                <textarea placeholder="Xabaringizni kiriting"></textarea>
                                                <button><i class="icofont icofont-send-mail"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> -->
                        </div>
                        <div class="details-content" v-else>
                            <p>Obuna aktivlashtirilmagan</p>
                            <p>Kechirasiz, sizda eksklyuziv filmlarni ko‘rsatish uchun aktiv obuna yo'q.</p>
                            <p>Iltimos eksklyuziv filmlarni ko'rish uchun obunalar bo‘limi orqali o‘zingizga mos tarifni aktivlashtiring.</p><br>
                            <a href="#" class="theme-btn" @click="saveOrder()"><i class="icofont icofont-ticket"></i> Sotib olish</a>
                        </div>
                    </div>
                    <div class="col-lg-3 text-center text-lg-left">
                        <div class="portfolio-sidebar">
                            <img src="/assets/img/background/4.jpg" alt="sidebar" />
                            <img src="/assets/img/background/2.jpg" alt="sidebar" />
                            <img src="/assets/img/background/17.jpg" alt="sidebar" />
                        </div>
                    </div>
                </div>
            </div>
        </section><!-- details area end -->
    </div>
</template>

<script>
export default {
	data() {
		return {
            slug: "",
            movie: [],
            link: ""
        }
	},
    methods: {
        getData() {
			var _this = this;
			this.$api.get("/apiData/Movie/Get?slug=" + this.slug).then(response => {
				_this.movie = response.result.data[0];
                _this.link = this.$store.state.fileUrl +`/moviesVideo/` + _this.movie.videos[0];
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
        parseDate(date) {
			var parse = '0';
			if(date && date != 0){
				parse = moment(date).format('DD.MM.YYYY');
			}
			return parse;
		},
        changeEpisode(url) {
            const videoPlayer = this.$refs.videoPlayer;
            videoPlayer.pause()
            this.link = this.$store.state.fileUrl +`/moviesVideo/` + url;
            videoPlayer.load();
            videoPlayer.play();
        },
        durationTime(time) {
            var hour = parseInt(time / 60);
            var minut = time % 60;
			var parse = minut + "minut";
			if(hour > 0){
				parse = hour + " soat " + minut + " minut";
			}
			return parse;
        },
        saveOrder() {
            let order = { movieId: this.movie.id };
			let sendModal = { order: order };
			this.$api.post('/apiData/Order/Post', sendModal).then(response => {
				if(response.result.isSuccess){
                    this.onPayme(response.orderId, response.userId);
				}
				else {
					this.$store.getters.toastr(this, "error", "Xatolik sodir bo‘ldi", response.error);
				}
			},
			err => {
				this.$store.getters.errorParse(this, err);
			});
		},
        onPayme(orderId, userId){
            let amount = this.movie.price;
            let merchantId = "6605186c5b347a44404c7cda";
            let urlBase64 = "m=" + merchantId + ";ac.user_id=" + userId + ";ac.order_id=" + orderId + ";a=" + amount +
            ";cr=uzs;ct=10000;c=https://kinoinsayder.uz/apiData/payment/payme;l=uz";
            window.location.href = "https://checkout.paycom.uz/" + btoa(urlBase64); 
        }
    },
	mounted() {
		this.getData();
	},
	created() {
		this.slug = this.$route.params.id;
		if (!this.slug) {
			this.$router.push({ path: "/" });
			return;
		}
	},
}
</script>